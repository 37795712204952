<template>
  <section id="talk">
    <base-card>
      <v-card-title
        primary-title
        class="display-2 justify-center font-weight-bold grey--text text--darken-3 mb-3"
      >
        What exactly can we do for you?
      </v-card-title>
      <v-card-text class="text-xs-center title font-weight-light mx-auto mb-5">
        You need a great website or an app that will boost your business? Tell us your goals and let the team of
        professionals bring your vision to life. And if business process automation is what you search for, we are eager
        to find the best option for you.
      </v-card-text>

      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex
            v-for="(feature, i) in features"
            :key="i"
            xs12
            sm6
            md4
          >
            <div class="text-xs-center">
              <v-icon
                :color="feature.color"
                :size="64"
                class="mb-3"
                v-text="feature.icon"
              />
              <p v-text="feature.text" />
            </div>
          </v-flex>
        </v-layout>
      </v-container>

      <v-divider class="my-5 mx-3" />

      <v-card-title
        primary-title
        class="display-2 justify-center font-weight-bold grey--text text--darken-3 mb-3"
      >
        Tell us about your plans
      </v-card-title>
      <v-card-text class="text-xs-center title font-weight-light mx-auto mb-5">
        Send us information about your project and make our experience work for you. We will respond swiftly.
      </v-card-text>
      <v-card-text>
        <v-form>
          <v-container grid-list-xl>
            <v-layout wrap>
              <v-flex
                xs12
                md6
              >
                <v-text-field label="Name" />
              </v-flex>
              <v-flex
                xs12
                md6
              >
                <v-text-field label="Email" />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  rows="16"
                  label="Message"
                />
              </v-flex>
              <v-flex
                text-xs-center
                xs12
              >
                <v-btn
                  color="primary"
                  class="font-weight-light"
                  large
                >
                  Send Message
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </base-card>
  </section>
</template>

<script>
  export default {
    data: () => ({
      features: [
        {
          icon: 'mdi-message-text',
          color: 'indigo',
          text:
            'We make websites and apps that perfectly match your business. Enjoy tailor-made solutions that deliver great user experience.'
        },
        {
          icon: 'mdi-shield-plus',
          color: 'green',
          text:
            'We can help you develop your agrarian or retail business with the most sophisticated software for business automation.'
        },
        {
          icon: 'mdi-widgets',
          color: 'primary',
          text:
            'Need an advice before taking the next important step? Feel free to share your ideas and increase your business efficiency.'
        }
      ]
    })
  }
</script>
